import { NextPage } from "next";
import Head from "next/head";
import Particles from "../components/particles";

const Home: NextPage = () => {
  return (
    <>
      <Head>
        <meta charSet={"UTF-8"} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/assets/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/assets/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/assets/favicon-16x16.png"
        />
        <link rel="manifest" href="/assets/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/assets/safari-pinned-tab.svg"
          color="#000000"
        />
        <meta name="theme-color" content="#000000" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={"/assets/ogp.jpg"} />
        <meta property="og:image:width" content={"1280px"} />
        <meta property="og:image:height" content={"720px"} />

        <title>SHIFT LINK | Visual Simulator</title>
        <meta name="description" content="SHIFT LINK | Visual Simulator" />
      </Head>
      <Particles />
    </>
  );
};

export default Home;
