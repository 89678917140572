import * as THREE from "three";
import { extend, MaterialNode } from "@react-three/fiber";
//import glsl from "babel-plugin-glsl/macro";

class PassthroughMaterial extends THREE.ShaderMaterial {
  constructor() {
    super({
      vertexShader: `varying vec2 vUv;
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }`,
      fragmentShader: `uniform sampler2D positions;
      uniform sampler2D prevPositions;
      varying vec2 vUv;
      void main() {
        vec4 pos = texture2D(positions, vUv);
        vec4 prev_pos = texture2D(prevPositions, vUv);

        pos = prev_pos;
        gl_FragColor = pos;
      }`,
      uniforms: {
        positions: {
          value: null
        },
        prevPositions: { value: null },
      },
    });
  }

  set setPrevPositions(v: any) {
    this.uniforms.prevPositions.value = v;
  }

}

extend({ PassthroughMaterial });

declare global {
  namespace JSX {
    interface IntrinsicElements {
      passthroughMaterial: MaterialNode<
        PassthroughMaterial,
        typeof PassthroughMaterial
      >;
    }
  }
}

<passthroughMaterial />;
