import React, {
  useRef,
  useState,
  useMemo,
  useEffect,
  useLayoutEffect,
} from "react";

React.useLayoutEffect = React.useEffect;
import { useMediaQuery } from "react-responsive";

import {
  Scene,
  OrthographicCamera,
  Vector3,
  Euler,
  SphereBufferGeometry,
  MeshStandardMaterial,
  Vector2,
  WebGLRenderTarget,
  NearestFilter,
  RGBAFormat,
  FloatType,
  Color,
  DynamicDrawUsage,
  LinearFilter,
  TextureDataType,
  HalfFloatType,
} from "three";
import * as STDLIB from "three-stdlib";

import { Canvas, createPortal, useFrame, useThree } from "@react-three/fiber";
import {
  Stats,
  useFBO,
  OrbitControls,
  PerspectiveCamera,
  PresentationControls,
} from "@react-three/drei";

import "../components/passthroughMaterial";
import "../components/simulationMaterial";

import { useControls, button } from "leva";
import { isMobile } from "react-device-detect";
//import { OrbitControls } from "three-stdlib";

let isCalc = true;

export default function Particles(props: any) {
  const refContainerStats = useRef<HTMLDivElement>(null);

  function Particle(props: any) {
    const particleCounts = props.counts;

    const simRef = useRef<JSX.IntrinsicElements["passthroughMaterial"]>(null);

    const simSecondRef =
      useRef<JSX.IntrinsicElements["simulationMaterial"]>(null);

    const [scene] = useState(() => new Scene());
    const [scene_] = useState(() => new Scene());

    const [camera] = useState(
      () => new OrthographicCamera(-1, 1, 1, -1, 1 / Math.pow(2, 53), 1)
    );

    const [camera_] = useState(
      () => new OrthographicCamera(-1, 1, 1, -1, 1 / Math.pow(2, 53), 1)
    );

    const [positions] = useState(
      () =>
        new Float32Array([
          -1, -1, 0, 1, -1, 0, 1, 1, 0, -1, -1, 0, 1, 1, 0, -1, 1, 0,
        ])
    );

    const [uvs] = useState(
      () => new Float32Array([0, 1, 1, 1, 1, 0, 0, 1, 1, 0, 0, 0])
    );

    const pos = new Vector3();
    const rot = new Euler();
    const scale = new Vector3(1.5, 1, 1);

    if (props.isFlip) {
      rot.set(Math.PI / 1, 0, 0);
    } else {
      rot.set(Math.PI / 2, 0, Math.PI / -1);
    }

    let dataType: TextureDataType;
    const { gl } = useThree();

    if (gl.capabilities.isWebGL2) {
      dataType = FloatType;
    } else {
      dataType = HalfFloatType;
    }

    const target = useFBO(particleCounts, particleCounts, {
      minFilter: NearestFilter,
      magFilter: NearestFilter,
      format: RGBAFormat,
      type: dataType,
    });

    const targetSecond = useFBO(particleCounts, particleCounts, {
      minFilter: NearestFilter,
      magFilter: NearestFilter,
      format: RGBAFormat,
      type: dataType,
    });

    let references = useMemo(() => {
      const length = particleCounts * particleCounts * 2;
      const references = new Float32Array(length);

      for (let i = 0; i < length; i++) {
        references[i * 2] = (i % particleCounts) / particleCounts;
        references[i * 2 + 1] = ~~(i / particleCounts) / particleCounts;
      }

      return references;
    }, [particleCounts]);

    const baseGeo = useMemo(() => {
      const baseGeo = new SphereBufferGeometry(0.005, 2, 2);
      return baseGeo;
    }, []);

    const vert = new Float32Array([
      -1.0, -1.0, 1.0, 1.0, -1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, 1.0, -1.0, 1.0,
      1.0, -1.0, -1.0, 1.0,
    ]);

    const material = useMemo(() => {
      const material = new MeshStandardMaterial();
      //const material = new MeshLambertMaterial();
      material.onBeforeCompile = function (shader) {
        shader.uniforms.particleSize = { value: props.particleSize };
        shader.uniforms.hueMix = { value: props.hueMix };

        shader.vertexShader =
          [
            `
                attribute vec2 reference;
                varying float id;
                uniform sampler2D positions;
                uniform float particleSize;
              `,
          ].join("\n") + shader.vertexShader;

        shader.vertexShader = shader.vertexShader.replace(
          "#include <project_vertex>",
          [
            `
                  vec4 noise_pos = texture2D(positions, reference);
                  vec3 vPosition = noise_pos.xyz + (transformed * particleSize);
                  vec4 mvPosition = modelViewMatrix * vec4( vPosition, 1.0 );
                  gl_Position = projectionMatrix * modelViewMatrix * vec4(vPosition, 1.0 );
                  //gl_position = vec4(0.,0.,0.,1.);
                  id = noise_pos.w;
                `,
          ].join("\n")
        );

        shader.fragmentShader =
          [
            `
                varying float id;
                uniform float hueMix;
    
                vec3 Hue(vec3 color, float hue) {
                  const vec3 k = vec3(0.57735, 0.57735, 0.57735);
                  float cosAngle = cos(hue);
                  return vec3(color * cosAngle + cross(k, color) * sin(hue) + k * dot(k, color) * (1.0 - cosAngle));
                }            
    
              `,
          ].join("\n") + shader.fragmentShader;

        shader.fragmentShader = shader.fragmentShader.replace(
          "#include <color_fragment>",
          [
            `
                #if defined( USE_COLOR_ALPHA )
                  diffuseColor *= vColor;
                #elif defined( USE_COLOR )
                  diffuseColor.rgb *= vColor;
                #endif
    
                /*
                vec4 col = diffuseColor;
                if(id < 50000.){
                  col = vec4(1.,1.,1.,1.);
                }
    
                diffuseColor = col;
                */
    
                vec3 c = Hue(diffuseColor.rgb, id*hueMix);
    
                diffuseColor = vec4(c,1.);
                `,
          ].join("\n")
        );

        material.userData.shader = shader;
      };

      return material;
    }, [props]);

    material.color = new Color(
      props.particleColor.r / 255,
      props.particleColor.g / 255,
      props.particleColor.b / 255
    );

    material.fog = true;
    material.roughness = props.roughness;

    useFrame((state) => {
      state.gl.setRenderTarget(target);
      state.gl.clear();
      state.gl.render(scene, camera);

      if (simRef.current != null) {
        simRef.current.setPrevPositions = targetSecond.texture;
      }

      state.gl.setRenderTarget(targetSecond);
      state.gl.clear();
      state.gl.render(scene_, camera_);
      state.gl.setRenderTarget(null);

      if (simSecondRef.current != null) {
        simSecondRef.current.setUTime = state.clock.elapsedTime * 0.1;
        simSecondRef.current.setPositions = target.texture;
        simSecondRef.current.setSpeed = props.speed;
        simSecondRef.current.setNoiseFreq = props.noiseFreq;
        simSecondRef.current.setNoiseSize = props.noiseSize * 0.01;
        simSecondRef.current.setNoiseSpeed = props.noiseSpeed;
        simSecondRef.current.setNoiseAdd = props.noiseAdd;

        simSecondRef.current.setStop = props.calculation;
      }
    });

    return (
      <>
        {createPortal(
          <>
            <mesh>
              <passthroughMaterial ref={simRef} />
              <bufferGeometry>
                <bufferAttribute
                  attachObject={["attributes", "position"]}
                  count={positions.length / 3}
                  array={positions}
                  itemSize={3}
                  onUpdate={(obj) => {
                    obj.setUsage(DynamicDrawUsage);
                  }}
                />
                <bufferAttribute
                  attachObject={["attributes", "uv"]}
                  count={uvs.length / 2}
                  array={uvs}
                  itemSize={2}
                  onUpdate={(obj) => {
                    obj.setUsage(DynamicDrawUsage);
                  }}
                />
              </bufferGeometry>
            </mesh>
          </>,
          scene
        )}

        {createPortal(
          <>
            <mesh>
              <simulationMaterial
                ref={simSecondRef}
                initParticle={particleCounts}
              />
              <bufferGeometry>
                <bufferAttribute
                  attachObject={["attributes", "position"]}
                  count={positions.length / 3}
                  array={positions}
                  itemSize={3}
                />
                <bufferAttribute
                  attachObject={["attributes", "uv"]}
                  count={uvs.length / 2}
                  array={uvs}
                  itemSize={2}
                  onUpdate={(obj) => {
                    obj.setUsage(DynamicDrawUsage);
                  }}
                />
              </bufferGeometry>
            </mesh>
          </>,
          scene_
        )}

        <mesh material={material} rotation={rot} position={pos} scale={scale}>
          <instancedBufferGeometry
            index={baseGeo.index}
            attributes-position={baseGeo.attributes.position}
            attributes-uv={baseGeo.attributes.uv}
            attributes-normal={baseGeo.attributes.normal}
            attach="geometry"
          >
            <instancedBufferAttribute
              attachObject={["attributes", "reference"]}
              args={[references, 4]}
              onUpdate={(obj) => {
                obj.setUsage(DynamicDrawUsage);
              }}
            />
          </instancedBufferGeometry>
        </mesh>
      </>
    );
  }

  const Effects = useMemo(() => {
    const Effects = () => {
      const {
        gl,
        scene: defaultScene,
        camera: defaultCamera,
        size,
        viewport,
      } = useThree();

      const dat = useControls("Effects", {
        bloom: { value: 0.6, min: 0.0, max: 1, step: 0.1 },
        feedback: { value: 0.9, min: 0.5, max: 0.93, step: 0.01 },
      });

      const composer = useMemo(() => {
        const composer = new STDLIB.EffectComposer(gl);
        const renderPass = new STDLIB.RenderPass(defaultScene, defaultCamera);

        composer.addPass(renderPass);
        return composer;
      }, [gl, defaultCamera, defaultScene]);

      const bloomPass = useMemo(() => {
        const bloomPass = new STDLIB.UnrealBloomPass(
          new Vector2(window.innerWidth, window.innerHeight),
          1.5,
          0.4,
          0.85
        );

        return bloomPass;
      }, []);

      useLayoutEffect(() => {
        bloomPass.strength = dat.bloom;
        bloomPass.threshold = 0.2;
        bloomPass.radius = 0.05;

        composer.addPass(bloomPass);

        return () => {
          composer.removePass(bloomPass);
        };
      }, [composer, dat, bloomPass]);

      let _width: number;
      let _height: number;

      const renderTarget = useMemo(() => {
        _width = window.innerWidth;
        _height = window.innerHeight;

        const renderTargetParameters = {
          minFilter: LinearFilter,
          magFilter: LinearFilter,
        };

        const renderTarget = new WebGLRenderTarget(
          _width,
          _height,
          renderTargetParameters
        );

        return renderTarget;
      }, [window.innerWidth, window.innerHeight]);

      useLayoutEffect(() => {
        const blendPass = new STDLIB.ShaderPass(
          STDLIB.BlendShader,
          "tDiffuse1"
        );
        const savePass = new STDLIB.SavePass(renderTarget);
        const outputPass = new STDLIB.ShaderPass(STDLIB.CopyShader);

        blendPass.uniforms["tDiffuse2"].value = savePass.renderTarget.texture;
        blendPass.uniforms["mixRatio"].value = dat.feedback;

        composer.addPass(blendPass);
        composer.addPass(savePass);
        composer.addPass(outputPass);

        return () => {
          composer.removePass(blendPass);
          composer.removePass(savePass);
          composer.removePass(outputPass);
        };
      }, [composer, dat, renderTarget]);

      useFrame((_, delta) => {
        gl.autoClear = true;
        composer.render(delta);
      }, 1);

      const resize = useMemo(() => {
        bloomPass.setSize(window.innerWidth, window.innerHeight);
        composer.reset(renderTarget);

        renderTarget.setSize(window.innerWidth, window.innerHeight);

        composer.renderer.setPixelRatio(viewport.dpr);
        composer.renderer.setSize(window.innerWidth, window.innerHeight);
      }, []);

      useEffect(() => {
        return () => {};
      }, [composer]);

      return null;
    };

    return Effects;
  }, []);

  useEffect(() => {
    const _onResize = () => {
      setResizeCount(resizeCount + 1);
    };

    window.addEventListener("resize", _onResize);

    return () => {
      window.removeEventListener("resize", _onResize);
    };
  });

  const [refresh, setRefresh] = useState(0);
  const [resizeCount, setResizeCount] = useState(0);

  const onResize = () => {
    setResizeCount(() => {
      return resizeCount + 1;
    });
  };

  const [showStats, setShowStats] = useState(false);

  function ParticlesSetup() {
    const [
      {
        speed,
        noiseFreq,
        noiseSize,
        noiseSpeed,
        //noiseAdd,
        colorA,
        colorB,
        hueMix,
        //roughness,
        size,
        calc,
      },
      set,
    ] = useControls("Particles", () => ({
      counts: {
        value: 65536,
        options: [1024, 4096, 16384, 65536, 262144, 1048576],
        onChange: (e) => {
          setRefresh(() => Math.sqrt(e));
        },
      },
      speed: { value: 1, min: 0.01, max: 3, step: 0.01 },
      noiseFreq: { value: 3, min: 0.01, max: 10, step: 0.01 },
      noiseSize: { value: 0.1, min: 0.01, max: 0.5, step: 0.01 },
      noiseSpeed: { value: 2.5, min: 0.1, max: 10, step: 0.1 },
      //noiseAdd: { value: 0, min: 0, max: 2, step: 0.1 },
      colorA: { r: 53, g: 53, b: 199 },
      colorB: { r: 16, g: 98, b: 109 },
      hueMix: { value: 1, min: 0.0, max: 3, step: 0.01 },
      //roughness: { value: 0.4, min: 0, max: 1, step: 0.01 },
      size: { value: 1, min: 0.1, max: 3, step: 0.1 },
      calc: { value: true },
      stats: {
        value: false,
        onChange: (e) => {
          if (e) {
            if (!refContainerStats.current) return;
            refContainerStats.current.style.visibility = "initial";
          } else {
            if (!refContainerStats.current) return;
            refContainerStats.current.style.visibility = "hidden";
          }
        },
      },
    }));

    const handleMediaQueryChange = (matches: boolean) => {
      setResizeCount(resizeCount + 1);
    };

    useMediaQuery(
      { orientation: "portrait" },
      undefined,
      handleMediaQueryChange
    );

    return (
      <>
        <Particle
          particleSize={size}
          particleColor={colorA}
          hueMix={hueMix}
          roughness={0.4}
          speed={speed}
          noiseFreq={noiseFreq}
          noiseSize={noiseSize}
          noiseSpeed={noiseSpeed}
          noiseAdd={0}
          calculation={calc}
          counts={refresh}
        />

        <Particle
          particleSize={size}
          particleColor={colorB}
          hueMix={hueMix}
          roughness={0.4}
          speed={speed}
          noiseFreq={noiseFreq}
          noiseSize={noiseSize}
          noiseSpeed={noiseSpeed}
          noiseAdd={0}
          calculation={calc}
          isFlip={true}
          counts={refresh}
        />
      </>
    );
  }

  return (
    <>
      <div key={resizeCount}>
        <Canvas gl={{ preserveDrawingBuffer: true }}>
          <PerspectiveCamera makeDefault position={[0, 0, 1.7]} />
          <ambientLight intensity={0.15} />
          <pointLight position={[1, 10, 0]} color={[1, 1, 1]} intensity={1} />

          <PresentationControls
            global
            snap={true}
            polar={[-Math.PI / 2, Math.PI / 2]}
            azimuth={[-Math.PI / 2, Math.PI / 2]}
            zoom={1}
            config={{ mass: 1, tension: 140, friction: 30 }} // Spring config
            //speed={1}
          >
            <ParticlesSetup />
          </PresentationControls>
          <OrbitControls
            enableRotate={false}
            enableDamping={true}
            enablePan={false}
            enableZoom={true}
            maxDistance={5}
            minDistance={0.6}
          />
          <Effects />
        </Canvas>
      </div>

      <div ref={refContainerStats} style={{ visibility: "hidden" }}>
        <Stats className={"stats"} parent={refContainerStats} />
      </div>
    </>
  );
}
